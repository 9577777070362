//@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Literata:ital,wght@0,200..900;1,200..900&display=swap');
@import url('_normalize.css');
@import url('_prism.css');
@import url('_reset.css');

html {
  // rainbow
  --color-red: #c1605c;
  --color-blue: #339af0;
  --color-cyan: #22b8cf;
  --color-yellow: #fcc419;
  --color-orange: #ff922b;

  // base colors
  --color-bg: #f3e9c6;
  --color-secondary: hsl(2, 45%, 56%);
  --color-text: #1b272f;
  --color-border: #3f474f;
  --color-text-inverse: #fafafa;

  // raw colors to allow us to do rgb(var(--color, 55)) for alpha
  --color-bg-rgb: 243, 233, 198;
  --color-secondary-rgb: 193, 96, 92;
  --color-text-rgb: 27, 39, 47;
  --color-border-rgb: 63, 71, 79;
  --color-text-inverse-rgb: 250, 250, 250;

  // sizes
  --padding: 0.5rem;
  --radius: 0.5rem;
  
  // font stuffs
  --font: "Work Sans", "Dosis", ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui, Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-headings: "Fraunces", "Yeseva One", serif;
  --font-size-small: 1rem;
  --font-size-medium: 1.25rem;
  --font-size: 1.4rem;
  --font-size-large: 1.675rem;
  --font-weight: 400;

  // other
  --border: 2px solid var(--color-border);
  --transition-length: 0.25s;
  --transition-length-slow: 0.45s;

  overflow-x: hidden;
}

body {
  // font
  color: var(--color-text);
  font-family: var(--font);
  font-size: var(--font-size);
  font-weight: var(--font-weight);

  // background
  background-repeat: repeat;
  background: url('../assets/noise.svg'), var(--color-bg);

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
  
  // misc
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* define header, footer, and main styling */
#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;

  &> * { 
    padding: var(--padding);
    width: 55vw;
    min-width: 320px;
    width: clamp(320px, 55vw, 100vw);
  }

  header {
    color: var(--color-text-inverse);
    padding: calc(2 * var(--padding)) var(--padding);
    font-family: var(--font-headings);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background: var(--color-secondary);
      border-bottom: 2px solid var(--color-border);
      inset: 0;
      width: 300vw;
      left: -100vw;
      height: 100%;
      z-index: -1;
    }

    & > a:first-child {
      font-weight: 900;
      font-size: 2rem;
    }

    nav {
      font-family: var(--font);
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;
      font-size: var(--font-size-medium);

      a { 
        margin: 0 0.25rem;
        border: var(--border);
        border-color: #0000;
        border-bottom-color: var(--color-border);
        transition: all var(--transition-length); 

        &:hover {
          background: var(--color-border);
          margin: 0;
          padding: 0 0.25rem;
          border: var(--border);
          border-radius: var(--radius);
        }
      }
    }
  }

  main {
    flex: 1 0 auto;
    padding-bottom: 0;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    
    & > * {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
