
.NowPage {
  padding: var(--padding) 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-bottom: 2px solid var(--color-border);

  .last-updated {
    font-size: 1rem;
    margin-top: 5rem;

    & > span {
      font-weight: 500;
      color: var(--color-secondary);
    }
  }

  & > * {
    width: 100%;
  }

  & > label {
    width: calc(100% + 2 * var(--padding));
  }

  h1, h2 {
    font-size: calc(1.25 * var(--font-size));
    width: 100%;

    a {
      font-weight: inherit;
      color: var(--color-secondary);
    }
  }
}

.NowPage .changelog-page {
  font-size: var(--font-size-medium);
  box-shadow: 0 0 2rem rgba(var(--color-border-rgb), 0.125), 0 0 2rem rgba(var(--color-border-rgb), 0.125);
  padding: var(--padding);
  width: calc(100% + 2 * var(--padding));
  border-radius: var(--radius);
  background: var(--color-border);
  color: var(--color-text-inverse);

  details {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    &:not(:last-child) { margin-bottom: 0.5rem; }
    p, span { font-size: var(--font-size-small); }
    & > * { margin-left: 0.5rem; }

    summary {
      cursor: pointer;
    }

    span {
      display: inline-flex;
      padding: 0.125rem 0.25rem;
      border: var(--border);
      border-radius: calc(var(--radius) / 2);
      background: var(--color-secondary);
      //color: var(--color-text);
      margin: 0.25rem;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}

input.section-toggle {
  display: none;

  & + section {
    cursor: pointer;

    .info {
      max-height: 0;
      padding: 0;
      transition: max-height 0.2s;
    }
  }

  &:checked + section {
    box-shadow: -0.5rem -0.5rem 0 0 var(--color-secondary);
    border-color: var(--color-border);

    .info {
      max-height: 6rem;
      padding: var(--padding);
    }
  }
}

.NowSection {
  padding: var(--padding);
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  h2 {
    font-size: calc(1.25 * var(--font-size));
    width: 100%;
  }

  .lists {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-grow: 1;
    flex-basis: 66%;
  }

  .info {
    min-width: 100%;
    border-radius: var(--radius);
    padding: var(--padding);
    font-size: 0.9em;
    background-color: var(--color-border);
    color: var(--color-text-inverse);
    max-height: 6rem;
    overflow-y: auto;

    * > p {
      display: flex;
      align-items: center;
    }

    progress {
      margin-left: 1rem;
      height: 0.5rem;

      appearance: none;
      border: none;
      border-radius: var(--radius);
      background-color: var(--color-bg);

      &::-moz-progress-bar {
        background-color: var(--color-secondary);
        border-radius: var(--radius);
      }
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem 0.25rem;
    align-items: center;

    input {  display: none; }

    & > span:first-child { font-weight: 600; }

    li label {
      cursor: pointer; 

      span {
        width: 100%;
        font-size: 0.9em;
        border: 2px solid var(--color-border);
        border-radius: calc(var(--radius) / 2);
        padding: 0.15rem 0.25rem;
        transition: all 0.2s;
        align-items: center;
      }

      input:checked + span,
      span:hover {
        background-color: var(--color-secondary);
        color: var(--color-text-inverse);
      }
    }
  }
}
