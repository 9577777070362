/* ======
keyframes
====== */ 
/* #region */

@keyframes small-spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg) translate(4rem 4rem);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes shift {
  from { 
    transform: translateX(-10px);
  }
  to { 
    transform:translateX(0);
  }
}
/* #endregion */

/* =========================
default styling for elements
========================= */
/* #region */

code, pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 1rem;
  line-height: 1;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-headings);
}

h1 {
  font-size: calc(var(--font-size) * 2);
  font-weight: 900;
}

h2 {
  font-size: calc(var(--font-size) * 1.8);
  font-weight: 800;
}

h3 {
  font-size: calc(var(--font-size) * 1.6);
  font-weight: 700;
  opacity: 0.95;
}

h4 {
  font-size: calc(var(--font-size) * 1.4);
  font-weight: 600;
  opacity: 0.95;
}

h5 {
  font-size: calc(var(--font-size) * 1.2);
  font-weight: 500;
  opacity: 0.9;
}

h6 {
  font-size: calc(var(--font-size) * 1.1);
  font-weight: 500;
  opacity: 0.9;
}

a { 
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  transition: filter var(--transition-length);

  &:hover {
    filter: drop-shadow(0 0 0.5rem rgba(var(--color-border-rgb), 0.25));
  }

  img {
    transition: transform var(--transition-length);
  }
  
  &:hover img {
    transform: scale(1.025);
  }
}
/* #endregion */

/* ============
styling classes 
============ */
/* #region */

a.special {
  color: inherit;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;

  &::before, &::after {
    content: '';
    inset: -2px;
    position: absolute;
    mix-blend-mode: difference;
    transition: all 0.2s ease-in-out;
    border-top-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }

  &::before {
    background-color: var(--color-text);
    right: 20%;
    bottom: 90%;
  }

  &::after {
    background-color: var(--color-text-inverse);
    top: 90%;
    left: 20%;
  }

  &:hover {
    &::before, &::after {
      inset: -2px;
    }
  };
}

.hover-block {
  position: relative;

  border: var(--border);
  border-radius: var(--radius);
  border-color: #0000;
  box-shadow: 0 0 0 0 #0000;

  transition-property: border-color, box-shadow;
  transition-duration: .4s;

  &:hover {
    box-shadow: -0.5rem -0.5rem 0 0 var(--color-secondary);
    border-color: var(--color-border);
  }
}
/* #endregion */

/* ==============
common components
============== */
/* #region */

.InlineTextRadio {
  display: none;

  & + label > span {
    color: var(--color-text-inverse);
    text-decoration: none;
    position: relative;
    z-index: 1;
    cursor: pointer;
    white-space: nowrap;
    margin: 0 0.15rem;

    &::after {
      content: '';
      position: absolute;

      border-radius: var(--radius);
      background: var(--color-border);
      border-color: var(--color-border);

      inset: 0.5rem;
      top: 100%;
      bottom: -0.25rem;

      transition: all var(--transition-length);
    }
  }

  // selected or hovered
  & + label > span:hover::after,
  &:checked + label > span::after {
    border: var(--border);
    border-width: 0.25rem;
    background: var(--color-border);
    
    inset: -0.25rem;
    top: -0.125rem;
    mix-blend-mode: difference;
  }

  // selected
  &:checked + label > span::after {
    background: none;
  }

  // hovered
  & + label > span:hover::after {
    background: var(--color-border);
  }
}
/* #endregion */
